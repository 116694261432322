import {
  Banner,
  Container,
  Helmet,
  Introduction,
  Layout,
  Link,
  Section,
  Theme,
  routesObject,
  ArticleCardsSection
} from "@life-without-barriers/react-components"

import { IGatsbyImageData } from "gatsby-plugin-image"
import React from "react"
import { Site, contentTypes as ct } from "@life-without-barriers/gatsby-common"
import { graphql } from "gatsby"
import { helpers, pageUrls } from "@life-without-barriers/utilities"

const { corporateThemeFull } = Theme
const { truncate } = helpers
const { generateMediaIndexUrl } = pageUrls

interface Props {
  location: { pathname: string }
  data: {
    site: Site
    ogImage: IGatsbyImageData
    articles: ct.ContentNodes<ct.MediaArticleSummary>
  }
  pageContext: {
    moreArticles: boolean
    category: string
    allCategories: string[]
    nextPage: number
  }
}

const IndexTemplate = ({
  location,
  data: {
    site: { siteMetadata },
    ogImage,
    articles
  },
  pageContext: { category, allCategories, nextPage }
}: Props) => {
  const mappedArticles = articles.edges.map(({ node }) => ({
    ...node,
    href: `/media/${node.slug}`,
    title: truncate(node.title, 65),
    copy: truncate(node.introductionText.text, 110),
    articleDate: node.date
  }))

  const newAllCategories = allCategories.map((category) => {
    if (category === "Aboriginal and Torres Strait Islander people") {
      return "Aboriginal and Torres Strait Islander People"
    } else if (category === "Child, youth and family") {
      return "Child, Youth and Family"
    } else if (category === "Refugees and asylum seekers") {
      return "Refugees and Asylum Seekers"
    } else {
      return category
    }
  })

  return (
    <div>
      <Helmet
        title={`Media releases and enquiries | ${siteMetadata.title}`}
        description="Life Without Barriers welcomes media enquiries. Find our latest media releases and stories here."
        siteUrl={siteMetadata.siteUrl}
        path={location.pathname}
        themeColor={corporateThemeFull.medium}
        image={ogImage}
      />
      <Layout theme={corporateThemeFull}>
        <Banner title="Media releases" breadcrumbs={[routesObject.home]} />
        <Section background="bg-lwb-white">
          <Container>
            <Introduction
              headingText="Latest Media Releases at Life Without Barriers"
              contentBlock={{
                kind: "inline",
                elements: [
                  <p key={0}>
                    Media Releases, statements and the latest news from across
                    our organisation as we strive to deliver excellent services,
                    break barriers to inclusion and change lives for the better.
                  </p>,
                  <p key={1}>
                    For media enquiries, please contact by emailing{" "}
                    <Link to="mailto:media@lwb.org.au">media@lwb.org.au</Link>
                  </p>,
                  <p key={2}>
                    If you would like to subscribe to our newsletter, please
                    click the button below.
                  </p>
                ]
              }}
              ctaButton={{
                kind: "link",
                to: "https://lwb.us17.list-manage.com/subscribe/post?u=ed0c7387c0a12d6ea2649b8af&id=d71d4ef458&f_id=0001a2e1f0",
                buttonText: "Subscribe to newsletter"
              }}
            />
            <ArticleCardsSection
              nextPage={nextPage}
              allCategories={newAllCategories}
              selectedCategory={category}
              articles={mappedArticles}
              generateIndexUrl={generateMediaIndexUrl}
              heading={"Latest media releases"}
            />
          </Container>
        </Section>
      </Layout>
    </div>
  )
}

export const query = graphql`
  query ($pageSize: Int, $categories: [String]) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    ogImage: file(relativePath: { regex: "/media/banner.png/" }) {
      childImageSharp {
        gatsbyImageData(formats: JPG, width: 1200)
      }
    }
    articles: allContentfulMediaArticle(
      filter: { category: { in: $categories } }
      limit: $pageSize
      sort: { fields: [date], order: DESC }
    ) {
      edges {
        node {
          ...MediaArticleSummary
        }
      }
    }
  }
`

export default IndexTemplate
